import axios from "axios";

export default class UserService {
    static async getAll(payload) {
        const params = { ...payload };
        Object.keys(params).forEach(key => (params[key] === undefined || params[key] === null || params[key] === '') && delete params[key]);
        return await axios.get(`/user-profile-service/admin/users`, { params });
    }

    static async getByUserId(userId) {
        return await axios.get(`/user-profile-service/admin/users/${userId}`);
    }

    static async login(email, password) {
        return await axios.post('/user-profile-service/auth', { email, password, withRoles: true });
    }

    static async refreshToken(refreshToken) {
        return await axios.post('/user-profile-service/auth/refresh-token', { refreshToken });
    }

    static async changePassword(userId, newPassword) {
        return await axios.put(`/user-profile-service/admin/users/${userId}/change-password`, { newPassword });
    }

    static async getAllByIds(userIds) {
        return await axios.post('/user-profile-service/admin/users/get-by-ids', userIds)
    }

    static async getUsersStatistics() {
        return await axios.get('/user-profile-service/admin/users/statistics')
    }
}